<script setup lang="ts">
import { computed } from 'vue'
import CheckoutSummaryItem from '/~/components/checkout/summary/checkout-summary-item.v4.vue'
import { useCheckoutReactive } from '/~/composables/checkout'

const { payment } = useCheckoutReactive()

const items = computed(() => {
  return payment.value.confirmation.order?.items ?? []
})
</script>

<template>
  <div v-if="items.length > 0" class="space-y-6">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="w-full"
      @click="$emit('item', item)"
    >
      <checkout-summary-item v-if="item" :item="item" />
    </div>
  </div>
</template>
