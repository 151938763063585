<script>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useEwalletSdk } from '/~/composables/ewallet-sdk'
import { useProvider } from '/~/composables/provider'
import ConfirmationPayTo from '/~/templates/cashrewards/views/checkout/components/confirmation-pay-to-cart.vue'
import CheckoutConfirmationDesktop from '/~/views/checkout.v2/views/checkout/views/checkout-confirmation.desktop.vue'

export default {
  name: 'checkout-mobile',
  components: {
    BaseAsidePage,
    ConfirmationPayTo,
  },
  extends: CheckoutConfirmationDesktop,
  setup() {
    const { ewalletLabels } = useCms()
    const { isUserAddressEnabled } = useProvider()
    const parent = CheckoutConfirmationDesktop.setup?.(...arguments)

    const { items, firstItem, selectedPurchaseCard, purchases, order } = parent

    const isGift = computed(() => items.value.some((item) => item.gift))
    const hasSingleItem = computed(() => {
      return items.value.length === 1
    })
    const firstItemPurchase = computed(() => {
      return purchases.active.hits?.find(
        (purchase) => purchase.orderNumber === order.value.number
      )
    })
    const isDeliveredToEwallet = computed(() => {
      if (!firstItemPurchase.value) {
        return false
      }

      return (
        ['giftcard', 'voucher'].includes(firstItem.value.type) &&
        firstItem.value.physical === false &&
        firstItem.value.dispatched
      )
    })
    const isViewGiftCard = computed(() => {
      return (
        hasSingleItem.value &&
        Boolean(firstItemPurchase.value) &&
        isDeliveredToEwallet.value
      )
    })
    const isAddressSelectionAllowed = computed(() =>
      items.value.some((item) => item.physical)
    )

    const route = useRoute()
    const router = useRouter()

    const { sendCloseEvent, sendViewGiftCardEvent, sendViewEwalletHomeEvent } =
      useEwalletSdk()

    function onLeave() {
      if (isGift.value) {
        sendCloseEvent()
      } else {
        sendViewEwalletHomeEvent()
        router.push({ name: 'home' })
      }
    }

    function onView() {
      if (!isViewGiftCard.value) {
        router.push({ name: 'home' })

        return
      }

      selectedPurchaseCard.value = firstItemPurchase.value

      const purchaseRoute = route.meta.epurchaseRoute
        ? `${route.meta.epurchaseRoute}/${selectedPurchaseCard.value.uuid}`
        : {
            hash: `#profile-e-purchase/${selectedPurchaseCard.value.uuid}`,
          }

      router.push(purchaseRoute)

      sendViewGiftCardEvent()
    }

    return {
      ...parent,
      isGift,
      isViewGiftCard,
      isAddressSelectionAllowed,
      onLeave,
      onView,
      ewalletLabels,
      isUserAddressEnabled,
    }
  },
}
</script>

<template>
  <base-aside-page :back="onLeave" title="Confirmation" no-padding shadow>
    <div class="flex h-screen w-full pt-5 sm:pt-0">
      <div
        class="flex h-full w-full flex-col rounded-t-3xl sm:mr-6 sm:pt-[30px]"
      >
        <div
          class="relative mx-auto flex min-h-full w-full max-w-lg flex-col sm:rounded-t-3xl sm:pt-[35px]"
        >
          <div class="flex grow flex-col px-5 pb-[15px]">
            <base-loader v-if="!isReady" fullwidth />

            <div
              v-else-if="failed"
              class="py-[30px] text-center text-2xl font-bold"
            >
              Order information not found

              <div class="mt-[30px]">
                <base-button full-width @click="$router.push({ name: 'home' })">
                  Back to Dashboard
                </base-button>
              </div>
            </div>

            <template v-else>
              <div class="grow">
                <div
                  class="flex items-center justify-center rounded-full"
                  :class="
                    order.statusLabel?.iconClass || order.statusLabel?.color
                  "
                >
                  <base-icon
                    :svg="
                      isFailed ? 'plain/close' : 'heroicons/solid/check-circle'
                    "
                    :size="isFailed ? 50 : 80"
                  />
                </div>
                <div class="mt-8 text-center text-sm text-eonx-neutral-800">
                  <div class="mb-3 text-xl font-bold">
                    Order {{ isFailed ? 'Failed' : 'Successful' }}
                  </div>
                  <div>Order No. #{{ paymentId }}</div>
                  <div v-if="formattedDate">
                    {{ formattedDate }}
                  </div>
                </div>
                <div class="mt-5 text-eonx-neutral-600">
                  <span v-if="isGift">
                    Your eGift card purchase has been sent to the recipient's
                    email address. Please note that in some cases, it may take
                    up to 72 hours for delivery to be completed.
                  </span>
                  <span v-else>
                    ePurchases will be delivered to your email and available in
                    {{ ewalletLabels.yourEwallet }}
                    instantly, however on occasion this may take up to 72 hours.
                  </span>
                </div>

                <points-panel
                  v-if="!isFailed && payment.isOrderPoints"
                  :total="pointsEarned"
                  :completed="
                    !order.isStatusGroupPending &&
                    !order.isStatusScheduled &&
                    !isStatementCurrentFlowType
                  "
                  class="mb-6 mt-[15px] rounded-lg"
                />

                <confirmation-addresses
                  v-if="isUserAddressEnabled && isAddressSelectionAllowed"
                  class="mb-5 border-b pb-5"
                  :order="order"
                />

                <div class="mt-8 font-bold">Items</div>

                <confirmation-pay-to :order="order" class="mb-5" />

                <!-- paid with -->
                <div v-if="!isFailed" class="mt-5 border-t py-5">
                  <div class="mb-2.5 font-bold">Paid with</div>

                  <div class="space-y-[15px]">
                    <confirmation-pay-with
                      v-for="method in order.paymentMethods"
                      :key="method.id"
                      :item="method"
                      :order="order"
                    />
                    <hr class="my-5 h-px w-full bg-gray-50" />
                  </div>
                </div>
                <confirmation-total v-if="!isFailed" :order="order" />
              </div>
            </template>
          </div>

          <div class="sticky bottom-0 bg-white p-5 shadow-md">
            <div class="flex w-full space-x-2.5">
              <div
                v-if="!isReady"
                class="mx-auto flex w-1/2 items-center justify-center"
              >
                <base-loader />
              </div>
              <base-button
                v-else-if="isViewGiftCard"
                look="outlined-color"
                class="mx-auto w-1/2"
                @click="onView"
              >
                {{ viewButtonTitle }}
              </base-button>
              <base-button
                v-if="isReady"
                class="mx-auto w-1/2"
                :class="{
                  'w-1/2': isViewGiftCard,
                  'w-full': !isViewGiftCard,
                }"
                @click="onLeave"
              >
                Done
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-aside-page>
</template>
